@import "_bootstrap-sass-grid";

html,body{
    overflow-x: hidden;
}
body{
	background: none;
	color: black;
    line-height: 28px;
    font-family: 'arial';
    font-size: 18px;
    font-weight: 100;
    min-height: 100vh;
    /* START video options*/
    #video{
        top: 0px;
        .videoBg{
            min-height: 100vh;
            /*
            background-image: url(../img/videoBg.jpg);
            background-position: center top;
            background-repeat: no-repeat;
            background-attachment: fixed;
            background-size: cover;
            */
            p.title{
                display: block;
                position: absolute;
                bottom: 0px;
                color: white;
                font-size:100px;
                font-weight: bold;
                text-transform: uppercase;
                left: 80px;
                span{
                    line-height: 170px;
                    display: block;
                    font-size: 130px;
                }
            }
            .social{
                width: 50px;
                position: absolute;
                left: 0px;
                top: 0px;
                background-color: white;
                height: 100vh;
                z-index: 990;
                p{
                    position: absolute;
                    z-index: 990;
                    top: 0px;
                    width: 90vh;
                    text-transform: uppercase;
                    left: 38px;
                    text-align: right;
                    transform: rotate(90deg);
                    bottom: 0px;
                    color: black;
                    transform: rotate(90deg);
	                transform-origin: left top 0;
                    i.icon-arrowright{
                        margin-left: 5px;
                        margin-right: 10px;
                        color: #c00039;
                    }
                    a{
                        color: #cccccc;
                        font-size: 25px;
                        transform: rotate(-90deg);
                        display: inline-block;
                        margin-right: 5px;
                        margin-left: 5px;
                        &:hover{
                            color: black;
                        }
                    }
                }
            }
            @media screen and (max-width:474px) {
                &{
                    background-size: auto !important
                }
                p.title{
                    font-size: 60px;
                    bottom: 30px;
                    span{
                        font-size: 80px;
                        line-height: 100px;
                    }
                }
                .social{
                }
            }
        }
    }
    /* START heads options */
    #head{
        width:100%;
        z-index: 998;
        position: absolute !important;
        .mobile-btn{
            display: none;
        }
        .logo{
            display: block;
            background-color: white;
            padding: 15px;
            img{
                max-width: 200px;
            }
        }
        .dropdown{
            background-color: #c00039;
            color: white;
            padding: 15px;
            font-weight: bold;
            margin-top: 15px;
            cursor: pointer;
            i{
                margin-left: 10px;
            }
            &:hover{
                background-color: white;
                color: black;
                padding-bottom: 50px;
                .dropdowns{
                    display: block;
                }
            }
            .dropdowns{
                display: none;
                padding: 15px;
                margin-top:50px;
                position: absolute;
                width: 300px;
                background-color: white;
                right: 15px;
                a{
                    margin-top: 15px;
                    position: relative;
                    overflow: hidden;
                    display: block;
                    h3{
                        width: 100%;
                        position: absolute;
                        font-size: 16px;
                        text-align: center;
                        background-color: rgba(0,0,0,.4);
                        margin-top: 105px;
                        color: white;
                        display: block;
                    }
                    &:hover{
                        h3{
                            background-color: rgba(229,26,75,.4);
                        }
                    }
                }
            }
        }
        @media screen and (max-width:865px) {
            .mobile-btn{
                display: block;
                position: absolute;
                right: 20px;
                color: white;
                font-size: 40px;
                margin-top: 25px;
                z-index: 999;
                .icon-close{
                    color: black;
                }
            }
        }
    }
}
